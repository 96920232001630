import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-error-message',
  standalone: true,
  imports: [CommonModule],
  template: `<p *ngIf="error" error class="text-error mt-2 text-sm">{{ error }}</p>`,
})
export class ErrorMessageComponent {
  @Input() error: string;
}
