/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BinaryFileListResource, BinaryFileService } from '@alfa-client/binary-file-shared';
import {
  CommandOrder,
  CommandResource,
  CommandService,
  CreateCommand,
  isDone,
} from '@alfa-client/command-shared';
import { NavigationService } from '@alfa-client/navigation-shared';
import {
  createEmptyStateResource,
  createStateResource,
  doIfLoadingRequired,
  StateResource,
} from '@alfa-client/tech-shared';
import { VorgangResource, VorgangService } from '@alfa-client/vorgang-shared';
import { hasLink, Resource } from '@ngxp/rest';
import { isNil } from 'lodash-es';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { KommentarLinkRel, KommentarListLinkRel } from './kommentar.linkrel';
import {
  CreateKommentarCommand,
  Kommentar,
  KommentarListResource,
  KommentarResource,
} from './kommentar.model';
import { KommentarRepository } from './kommentar.repository';

@Injectable({ providedIn: 'root' })
export class KommentarService {
  readonly kommentarList$: BehaviorSubject<StateResource<KommentarListResource>> =
    new BehaviorSubject(createEmptyStateResource<KommentarListResource>());
  readonly formularVisibility$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private navigationSub: Subscription;

  constructor(
    private repository: KommentarRepository,
    private commandService: CommandService,
    private navigationService: NavigationService,
    private vorgangService: VorgangService,
    private binaryFileService: BinaryFileService,
  ) {
    this.listenToNavigation();
  }

  private listenToNavigation(): void {
    this.unsubscribe();
    this.navigationSub = this.navigationService
      .urlChanged()
      .subscribe((params: Params) => this.onNavigation(params));
  }

  private unsubscribe(): void {
    if (!isNil(this.navigationSub)) this.navigationSub.unsubscribe();
  }

  onNavigation(params: Params): void {
    if (NavigationService.isVorgangListPage(params)) {
      this.setKommentarListOnReload();
    }
    if (NavigationService.isVorgangDetailPage(params, VorgangService.VORGANG_WITH_EINGANG_URL)) {
      this.setKommentarListOnReload();
    }
  }

  private setKommentarListOnReload(): void {
    this.kommentarList$.next({ ...this.kommentarList$.value, reload: true });
  }

  public getKommentareByVorgang(
    vorgang: VorgangResource,
  ): Observable<StateResource<KommentarListResource>> {
    doIfLoadingRequired(this.kommentarList$.value, () => this.loadKommentare(vorgang));
    return this.kommentarList$.asObservable();
  }

  private loadKommentare(vorgang: VorgangResource): void {
    this.setListLoadingTrue();

    const sub: Subscription = this.repository
      .findKommentare(vorgang)
      .subscribe((kommentarList: KommentarListResource) => {
        this.setKommentarList(kommentarList);
        sub.unsubscribe();
      });
  }

  setListLoadingTrue(): void {
    this.kommentarList$.next({ ...this.kommentarList$.value, loading: true });
  }

  setKommentarListReload() {
    this.kommentarList$.next({ ...this.kommentarList$.value, reload: true });
  }

  setKommentarList(kommentarList: KommentarListResource): void {
    this.kommentarList$.next(createStateResource(kommentarList));
  }

  public isFormularVisible(): Observable<boolean> {
    return this.formularVisibility$.asObservable();
  }

  public canCreateNewKommentar(kommentareListResource: KommentarListResource): Observable<boolean> {
    return this.formularVisibility$.pipe(
      map(
        (formularVisibility) =>
          !formularVisibility &&
          hasLink(kommentareListResource, KommentarListLinkRel.CREATE_KOMMENTAR),
      ),
    );
  }

  public showFormular(): void {
    this.formularVisibility$.next(true);
  }

  public hideFormular(): void {
    this.formularVisibility$.next(false);
  }

  public createKommentar(kommentar: Kommentar): Observable<StateResource<CommandResource>> {
    return this.createKommentarCommand(
      this.kommentarList$.value.resource,
      KommentarListLinkRel.CREATE_KOMMENTAR,
      this.createCreateKommentarCommand(kommentar),
    );
  }

  createCreateKommentarCommand(kommentar: Kommentar): CreateKommentarCommand {
    return { order: CommandOrder.CREATE_KOMMENTAR, kommentar, body: null };
  }

  public editKommentar(
    kommentar: KommentarResource,
    toPatch: Kommentar,
  ): Observable<StateResource<CommandResource>> {
    return this.createKommentarCommand(
      kommentar,
      KommentarLinkRel.EDIT,
      this.createEditKommentarCommand(toPatch),
    );
  }

  createEditKommentarCommand(kommentar: Kommentar): CreateKommentarCommand {
    return { order: CommandOrder.EDIT_KOMMENTAR, kommentar, body: null };
  }

  createKommentarCommand(
    resource: Resource,
    linkRel: string,
    command: CreateCommand,
  ): Observable<StateResource<CommandResource>> {
    return this.commandService.createCommand(resource, linkRel, command).pipe(
      tap((createdCommand: StateResource<CommandResource>) =>
        this.afterCreateOrEditKommentar(createdCommand),
      ),
      startWith(createEmptyStateResource<CommandResource>(true)),
    );
  }

  afterCreateOrEditKommentar(command: StateResource<CommandResource>): void {
    if (isDone(command.resource)) {
      this.hideFormular();
      this.setKommentarListReload();
      this.vorgangService.reloadCurrentVorgang();
    }
  }

  getAttachments(kommentar: KommentarResource): Observable<StateResource<BinaryFileListResource>> {
    if (hasLink(kommentar, KommentarLinkRel.ATTACHMENTS)) {
      return this.binaryFileService.getFiles(kommentar, KommentarLinkRel.ATTACHMENTS);
    }
    return of(createEmptyStateResource<BinaryFileListResource>());
  }
}
